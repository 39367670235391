<template>
  <div>
    <div>
      <iframe :src="lay" 
        style="position: relative; height: 91vh; width: 100%;" scrolling="no" frameborder="0"
      ></iframe>
    </div>
  </div>
</template>

<script>
// import KTCodePreview from "@/view/content/CodePreview.vue";
// import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";

export default {
  data() {
    return {
     lay: "/maps/map.html?mapType=forecast&lay=nesdis24&menu=F"
    };
  },
  components: {
    // KTCodePreview
  },
  beforeMount(){
    // this.lay = this.route.params
    // console.log('ruta:',this.$route.query.lay);
    // this.lay = this.$route.query.lay;
  },
  mounted() {
  },
  watch:{
    $route (to){
      // console.log(to.query.lay);
        // this.lay = to.query.lay;
        if(to.query.lay == 1){
          this.lay = "https://maps-uc.web.app/map.html?mapType=forecast&lay=nesdis24&menu=F"
        } else {
          this.lay =  "https://maps-uc.web.app/map.html?mapType=satellite&z=7&lat=8.53&lon=-80.78"
        }
        // console.log('route change: ',to,' ',from)
    }
  } 
};
</script>
